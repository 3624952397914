<template>
  <div>
    <div id="m" v-bind="$attrs">
      <span v-if="!mapIsReady">Načítání mapy...</span>
    </div>
    <slot v-if="mapIsReady"></slot>
  </div>
</template>

<script>
import { ref, provide, inject, onMounted, onBeforeUpdate } from "vue";

export default {
  name: "SMap",
  props: {
    centerLngLat: {
      // Map center (reactive)
      type: [Object, Array],
      required: true,
    },
    zoom: {
      // Zoom level (initial value only)
      type: Number,
      default: 11,
    },
  },
  emits: ["mapCenterChanged", "viewportChanged"],
  setup(props, { emit }) {
    const mapIsReady = ref(false);
    const map = ref(null);

    const SMap = inject("SMap").value;
    const JAK = inject("JAK").value;

    provide("map", map);

    onMounted(async () => {
      let center = SMap.Coords.fromWGS84(
        props.centerLngLat[0],
        props.centerLngLat[1]
      );
      map.value = new SMap(JAK.gel("m"), center, props.zoom);
      map.value.addDefaultLayer(SMap.DEF_BASE).enable();

      map.value.getSignals().addListener(map.value, "map-redraw", function() {
        let center = map.value.getCenter();
        if (
          props.centerLngLat[0] != center.x &&
          props.centerLngLat[1] != center.y
        ) {
          emit("mapCenterChanged", { lng: center.x, lat: center.y });
        }

        let vp = map.value.getViewport();
        emit("viewportChanged", {
          leftBottom: { lng: vp.lbx, lat: vp.lby },
          rightTop: { lng: vp.rtx, lat: vp.rty },
        });
      });

      mapIsReady.value = true;
    });

    onBeforeUpdate(() => {
      let center = SMap.Coords.fromWGS84(
        props.centerLngLat[0],
        props.centerLngLat[1]
      );
      map.value.setCenter(center, true);

      // map.value.setZoom(props.zoom);
    });

    return { mapIsReady };
  },
};
</script>

<style scoped></style>
